import "../styles/NousContacter.css"

function NousContacter()
{
    return (
        <div className="contact corps">
            <h1>Coordonnées de contact :</h1>
            <div className="info-contact">
                <h2>Adresse mail :</h2>
                <p className="adress-mail-ordi"><a href="mailto:sosdiogene@yahoo.com?">sosdiogene@yahoo.com</a></p>
                <p className="adress-mail-tel"><a href="mailto:sosdiogene@yahoo.com?">sosdiogene@</a><br>
                </br><a href="mailto:sosdiogene@yahoo.com?">yahoo.com</a></p>
            </div>
            <div className="info-contact">
                <h2>Numéros de téléphone :</h2>
                <p>09.73.88.59.68 / 06.13.15.20.93</p>
            </div>
            <div className="info-contact">
                <h2>Adresse :</h2>
                <p>10/14 Avenue du Général Leclerc<br></br>78230 LE PECQ</p>
            </div>
        </div>
    );
}

export default NousContacter;