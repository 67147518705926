import { useEffect, useState } from 'react';
import '../styles/App.css';
import Banniere from './Banniere';
import Corps from './Corps';
import Pied from './Pied';
import axios from "axios";
import chemin from "./Chemins"

function App() {
  const [page, choixPage] = useState(0);
  const [connecte, authentifier] = useState(true);
  const [idUtilisateur, changerIdUtilisateur] = useState(0);

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get(chemin + "/utilisateurs/connexion").then((res) => {
      authentifier(res.data.connecte);
      changerIdUtilisateur(res.data.utilisateur);
    });
  }, []);
  return (
      <div className="App">
        <Banniere page={page} choixPage={choixPage}/>
        <Corps page={page} choixPage={choixPage} connecte={connecte} authentifier={authentifier} 
        idUtilisateur={idUtilisateur} changerIdUtilisateur={changerIdUtilisateur}/>
        <Pied page={page} choixPage={choixPage}/>
      </div>
    );
}

export default App;