import "../styles/Accueil.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import debarras from "../images/debarras.jpg"
import menage from "../images/menage.jpg"
import bricolage from "../images/bricolage.jpg"

function Accueil({page, choixPage})
{
    return (
        <div className="accueil corps">
            <h1>SOS DIOGENE</h1>
            <div className="accueil-contact">
                Besoin d'aide à domicile, d'un entretien pour vos locaux, ou encore de bien d'autres services tels qu'une désinfection 
                de vos locaux ou encore le lessivages de vos murs, plafonds et sols ? Rendez-vous dans votre 
                <b className="lien-espace-perso" onClick={() => {choixPage(8); window.scrollTo(0, 0);}}> espace personnel </b>  
                pour faire une demande de devis ou contactez nous immédiatement pour la réalisation d'un devis <b>gratuitement </b> 
                et <b>sans engagement </b> !<br></br>
                <button className="contactez-nous" onClick={() => {choixPage(7); window.scrollTo(0, 0);}}>CONTACTEZ-NOUS</button>
            </div>
            <div className="accueil-services">
                <h1>Nos services les plus demandés</h1>
                <Carousel showThumbs={false}>
                    <div>
                        <img src={debarras} alt="Débarras" className="image-accueil"></img>
                        <div className="overlay">
                            <h2>Grand nettoyage</h2>
                            <p>Votre maison est trop encombrée ? L'accumulation d'objets peut augmenter les risques d'incendies dans 
                                les habitations et devenir une gêne pour se déplacer. SOS Diogene peut vous aider, au cours de nos 
                                nombreuses missions nous avons acquis un grand savoir faire dans le grand nettoyage et nos équipes 
                                peuvent agir rapidement, sans vous déranger dans votre quotidien !
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={menage} alt="Ménage" className="image-accueil"></img>
                        <div className="overlay">
                            <h2>Agent d'entretien</h2>
                            <p>Pour un entretien ponctuel ou régulier, vous pouvez comptez sur nos agents ! Ils rendront à votre 
                                habitation tout son éclat avec la bonne humeur qui nous caractérise.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={bricolage} alt="Bricolage" className="image-accueil"></img>
                        <div className="overlay">
                            <h2>Bricolage</h2>
                            <p>Besoin d'aide pour monter une armoire, une étagère ou tout autre pièce de mobilier ? Vous pouvez faire 
                                appelle à SOS Diogene, nous vous enverrons nos meilleurs agents qui viendront à bout des montages les 
                                plus compliqués en un rien de temps !
                            </p>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    );
}

export default Accueil;