import "../styles/Recrutement.css"
import chemin from "./Chemins"
import axios from "axios"

function verifierInfo(formulaire)
{
    if (formulaire.nom === "" || !formulaire.nom.trim())
        return ("Le nom n'est pas renseigné")
    else if (formulaire.prenom === "" || !formulaire.prenom.trim())
        return ("Le prénom n'est pas renseigné")
    else if (formulaire.mail ==="" || !/\S+@\S+\.\S+/.test(formulaire.mail))
        return ("Veuillez renseignée une adresse mail correcte")
    else if (formulaire.tel.length !== 10 || !/^[0-9]+$/.test(formulaire.tel))
        return ("Veuillez renseigner un numéro de téléphone valide dans le format 0102030405")
    else if (formulaire.cv === "" || formulaire.cv.type !== "application/pdf")
        return ("Veuillez joindre un CV au format pdf")
    else if (formulaire.motiv === "" || !formulaire.motiv.trim())
        return ("Veuillez remplir le champs \"Lettre de motivation\"");
    else if (formulaire.poste === "")
        return ("Veuillez sélectionner un poste souhaité")
    else
        return ("OK");
}

function Recrutement({choixPage})
{
    let formulaire = {
        nom: "",
        prenom: "",
        mail: "",
        tel: "",
        cv: "",
        motiv: "",
        poste: "",
    }
    return (
        <div className="recrutement">
            <h1>Recrutement : </h1>
            Vous souhaitez rejoindre SOS Diogene ? Il suffit de remplir les champs ci-dessous et nous vous recontacterons pour vous 
            informer de l'avancement de votre candidature !
            <div className="form-recrutement">
                <div className="info-perso">
                    <p>
                        Nom:<br></br>
                        <input type="text" id="nom" name="nom" onChange={(e) => {formulaire.nom = e.target.value}}></input>
                    </p>
                    <p>
                        Prénom:<br></br>
                        <input type="text" id="prenom" name="prenom" onChange={(e) => {formulaire.prenom = e.target.value}}></input>
                    </p>
                    <p>
                        Email:<br></br>
                        <input type="email" id="email" name="email" onChange={(e) => {formulaire.mail = e.target.value}}></input>
                    </p>
                    <p>
                        Téléphone:<br></br>
                        <input type="tel" id="telephone" name="telephone" pattern="[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2" 
                        onChange={(e) => {formulaire.tel = e.target.value}}></input>
                    </p>
                </div>
                <p>
                    CV :<br></br>
                    <input type="file" id="cv" name="cv" accept=".pdf" 
                    onChange={(event) => {formulaire.cv = event.target.files[0]}}></input>
                </p>
                <p>
                    Lettre de Motivation :<br></br>
                    <textarea className="lettre_motivation" id="lettre_motivation" name="lettre_motivation" rows="10" cols="100"
                    onChange={(e) => {formulaire.motiv = e.target.value}}></textarea>
                </p>
                <p>
                    Poste Souhaité:<br></br>
                    <select id="poste" name="poste" onChange={(e) => {formulaire.poste = e.target.value}}>
                        <option value="">Sélectionnez le poste</option>
                        <option value="Femme de ménage">Femme de ménage</option>
                        <option value="Auxiliaire de vie">Auxiliaire de vie</option>
                        <option value="Homme toutes mains">Homme toutes mains</option>
                        <option value="Responsable de chantier">Responsable de chantier</option>
                    </select>
                </p>
            </div>
            <button className="soumettre" onClick={() => {
                const message_erreur = verifierInfo(formulaire);
                if (message_erreur !== "OK")
                    alert(message_erreur);
                else 
                {
                    const formData = new FormData();
                    const nom_cv = Date.now() + "CV_" + formulaire.nom + "-" + formulaire.prenom + ".pdf";
                    formData.append('cv', formulaire.cv, nom_cv);
                    axios.post(chemin + "/recrutement/telecharger_cv", formData).then((res) => {
                        console.log(res.data.message);
                    });
                    formulaire.cv = nom_cv;
                    axios.post(chemin + "/recrutement/envoyer_demande", formulaire).then((res) => {
                        console.log(res.data.message);
                    });
                    alert("Votre candidature a bien été envoyée, nous vous recontacterons dans le délai le plus court ! Vous allez désormais être redirigé sur la page d'accueil.")
                    choixPage(0);
                    window.scrollTo(0, 0);
                }
            }}>Soumettre</button>
        </div>
    );
}

export default Recrutement;