import "../styles/Banniere.css"
import logo from "../images/logo_sos.png"
import { useState } from "react";

/*function Banniere({page, choixPage})
{
    const [test, activeTest] = useState(0);

    return (
        <div className="banniere">
            <ul className="menu-deroulant">
                <li className="curseur-pointeur" onClick={() => {choixPage(8);window.scrollTo(0, 0);}}>
                    <b>Espace personnel</b>
                </li>
                <li className=" curseur-pointeur" 
                    onMouseEnter={() => {activeTest(1)}} 
                    onMouseLeave={() => {activeTest(0)}}>
                    <b>Qui sommes nous ?</b>
                    {test === 1 && 
                        <ul className="liste-deroulante">
                            <li className=" curseur-pointeur" onClick={() => {choixPage(1); window.scrollTo(0, 0);}}>Notre fonctionnement</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(2); window.scrollTo(0, 0);}}>Notre zone d'intervention</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(3)}}>Nos chantiers</li>
                        </ul>}
                </li>
                <li className=" curseur-pointeur" 
                onMouseEnter={() => {activeTest(2)}} 
                onMouseLeave={() => {activeTest(0)}}>
                    <b>Quels sont nos services ?</b>
                    {test === 2 && 
                        <ul className="liste-deroulante">
                            <li className=" curseur-pointeur" onClick={() => {choixPage(4); window.scrollTo(0, 0);}}>Aide à domicile</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(5); window.scrollTo(0, 0);}}>Ménages</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(6); window.scrollTo(0, 0);}}>Multi-services</li>
                        </ul>}
                </li>
                <li className=" curseur-pointeur" onClick={() => {choixPage(7); window.scrollTo(0, 0);}}>
                    <b>Nous joindre</b>
                </li>
                <li className=" curseur-pointeur" onClick={() => {choixPage(13); window.scrollTo(0, 0);}}>
                    <b>Recrutement</b>
                </li>
            </ul>
            <img src={logo} alt="logo" className="img-logo curseur-pointeur" onClick={() => {choixPage(0); window.scrollTo(0, 0);}}></img>
        </div>
    );
}*/

function Banniere({choixPage})
{
    const [visible, setVisible] = useState([false, false]) 

    return (
        <div className="banniere">
            <div className="haut">
                <p className="objet-menu" onClick={() => {choixPage(8);window.scrollTo(0, 0);}}>Espace personnel</p>
                <p className="objet-menu"
                onMouseEnter={() => {setVisible([true, false])}} onMouseLeave={() => {setVisible([false, false])}}>Qui sommes nous</p>
                <p className="objet-menu"
                onMouseEnter={() => {setVisible([false, true])}} onMouseLeave={() => {setVisible([false, false])}}>Nos services</p>
                <p className="objet-menu"
                onClick={() => {choixPage(7); window.scrollTo(0, 0);}}>Nous contacter</p>
                <p className="objet-menu"
                onClick={() => {choixPage(13); window.scrollTo(0, 0);}}>Recrutement</p>
                <p></p>
            </div>
            {visible[0] && 
            <div className="qsn" onMouseEnter={() => {setVisible([true, false])}} onMouseLeave={() => {setVisible([false, false])}}>
                <p className="fct objet-menu"
                onClick={() => {choixPage(1); window.scrollTo(0, 0);}}>Notre fonctionnement</p>
                <p className="inter objet-menu"
                onClick={() => {choixPage(2); window.scrollTo(0, 0);}}>Notre zone d'intervention</p>
            </div>}
            {visible[1] && 
            <div className="serv" onMouseEnter={() => {setVisible([false, true])}} onMouseLeave={() => {setVisible([false, false])}}>
                <p className="ad objet-menu"
                onClick={() => {choixPage(4); window.scrollTo(0, 0);}}>Aide à domicile</p>
                <p className="menage-ban objet-menu"
                onClick={() => {choixPage(5); window.scrollTo(0, 0);}}>Ménage</p>
                <p className="autre objet-menu"
                onClick={() => {choixPage(6); window.scrollTo(0, 0);}}>Autres services</p>
            </div>}
            <img src={logo} className="logo_sos" alt="Logo sos diogene" onClick={() => {choixPage(0); window.scrollTo(0, 0);}}></img>
        </div>
    );
}

export default Banniere;