import "../styles/Connexion.css";
import axios from "axios";
import chemin from "./Chemins"

function Connexion({connecte, authentifier, idUtilisateur, changerIdUtilisateur, page, choixPage})
{
    let utilisateur = {mail: "", mdp: ""};

    return (
        <div className="connexion">
            <h1>Veuillez vous authentifier pour accéder à votre espace personnel :</h1>
            <div className="form-connexion">
                Adresse mail :<br></br>
                <input id="connexion-mail" type="text" className="nom-utilisateur" onChange={(event) => {utilisateur.mail = event.target.value;}}></input>
                <br></br>
                Mot de passe :<br></br>
                <input id="connexion-mdp" type="password" className="mot-de-passe" onChange={(event) => {utilisateur.mdp = event.target.value;}}></input>
                <br></br>
                <p className="mdp-oublie" onClick={() => {choixPage(15);}}>Mot de passe oublié</p>
            </div>
            <button type="submit" className="se-connecter" onClick={() => {
                axios.post(chemin + "/utilisateurs/connexion", utilisateur).then((res) => {
                    if (res.data.error === undefined)
                    {
                        authentifier(true);
                        changerIdUtilisateur(res.data.id);
                    }
                    else
                        alert(res.data.error);
                });
            }}>Envoyer</button>
            <br></br>
            <h3>Pas encore de compte ? <span className="bouton-creer" onClick={() => {choixPage(14); window.scrollTo(0, 0);}}>Créer un compte</span></h3>
        </div>
    );
}

export default Connexion;