import "../styles/Pied.css"
import logosap from "../images/logosap.png"
import logoeco from "../images/certif-ecodrop.png"
import logoci from "../images/logocreditimpot.png"

function Pied ({page, choixPage})
{
    return (
        <div className="pied">
            <span className="mentions" onClick={() => {choixPage(9); window.scrollTo(0, 0);}}>Mentions légales</span>
            <a href="https://www.servicesalapersonne.gouv.fr/" rel="noreferrer" target="_blank">
                <img src={logosap} alt="Logo SaP" className="logosap"></img>
            </a>
            <a href="https://www.ecodrop.net/label-ecoresponsable-ecodrop-1/" rel="noreferrer" target="_blank">
                <img src={logoeco} alt="Logo certif ecodrop" className="logoeco"></img>
            </a>
            <a href="https://www.economie.gouv.fr/particuliers/credit-impot-services-personne" rel="noreferrer" target="_blank">
                <img src={logoci} alt="Logo crédit d'impot" className="logoci"></img>
            </a>
        </div>
    );
}

export default Pied;