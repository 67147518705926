import "../styles/EspacePersonnel.css"
import axios from "axios";
import chemin from "./Chemins"
import Connexion from "./Connexion";

function EspacePersonnel({connecte, authentifier, page, choixPage, idUtilisateur, changerIdUtilisateur})
{
    if (connecte === true)
        return (
            <div className="espace-personnel corps">
                <button className="bouton-espace-perso" onClick={() => {choixPage(11); window.scrollTo(0, 0);}}>Voir mes demandes de devis</button><br></br>
                <button className="bouton-espace-perso" onClick={() => {choixPage(12); window.scrollTo(0, 0);}}>Faire une nouvelle demande de devis</button><br></br>
                <button className="bouton-espace-perso" onClick={() => {choixPage(16); window.scrollTo(0, 0);}}>Changer le mot de passe</button><br></br>
                <button onClick={() => {
                    axios.post(chemin + "/utilisateurs/deconnexion").then((res) => {
                        authentifier(false);
                        choixPage(0);
                    });
                }} className="bouton-espace-perso">Se déconnecter</button>
            </div>
        );
    else
        return (
            <div className="espace-personnel corps">
                <Connexion connecte={connecte} authentifier={authentifier} 
                idUtilisateur={idUtilisateur} changerIdUtilisateur={changerIdUtilisateur}
                page={page} choixPage={choixPage}/>
            </div>
    );
}

export default EspacePersonnel;