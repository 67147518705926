import "../styles/Menage.css"

function Menage()
{
    return (
        <div className="menage corps">
            <h1 className="titre">Nos services de ménages :</h1>
            <div className="menage-desc">
                <h2>Particuliers ou professionels, nous sommes en mesure d'entretenir tout type de locaux :</h2>
                Offrez à votre espace la propreté et la fraîcheur qu'il mérite avec notre service de ménage professionnel. Que ce soit 
                pour des bureaux, des résidences, des commerces ou tout autre type de local, notre équipe expérimentée est là pour 
                répondre à tous vos besoins en matière d'entretien. Nous proposons un nettoyage minutieux et efficace, adapté à vos 
                exigences spécifiques. De l'époussetage des surfaces à l'aspiration des sols en passant par la désinfection des 
                sanitaires, nous assurons un environnement impeccable et hygiénique. Confiez-nous vos locaux et profitez d'un espace 
                propre et accueillant, jour après jour.
                <br></br><br></br>
                <h2>Nos demandes d'entretiens les plus fréquentes :</h2>
                <ul className="menage-list">
                    <li>Maisons</li>
                    <li>Appartements</li>
                    <li>Bureaux, Boutiques</li>
                    <li>Entrepôts</li>
                    <li>Parties communes</li>
                    <li>Espaces verts</li>
                </ul>
            </div>
        </div>
    );
}

export default Menage;