import "../styles/Aide.css"
import imageAVC from "../images/aideActeVieCourante.jpg"
import imageGarde from "../images/garde.jpg"
import imageRemplacements from "../images/remplacement.jpg"
import imageAgentEntretien from "../images/agentEntretien.jpg"
import imageLivraisonRepas from "../images/livraisonRepas.jpg"

function AideADomicile()
{
    return (
        <div className="aide corps">
            <h1 className="titre">Nos services d'aide à domicile :</h1>
            <div className="aide-avc">
                <img src={imageAVC} alt="aide à la vie courante" className="img-aide"></img>
                <div className="aide-avc-texte">
                    <h2>Aide pour les actes de la vie courante :</h2>
                    Notre service d'Aide pour les actes de la vie courante offre une assistance complète pour simplifier le quotidien 
                    de nos clients. Nous prenons en charge l'entretien du domicile et du linge, ainsi que les courses et achats 
                    essentiels. Nos professionnels sont là pour préparer des repas équilibrés et offrir une aide précieuse lors de leur 
                    prise. En plus de l'aide personnelle, nous accompagnons nos clients dans leurs démarches administratives simples. 
                    Notre engagement va au-delà : nous assurons également un soutien social, une présence rassurante et une 
                    surveillance attentive pour leur bien-être global.
                </div>
            </div>
            <div className="garde">
                <div>
                    <h2>Garde à demeure :</h2>
                    Offrez à votre famille une tranquillité d'esprit totale avec notre service de garde à domicile. 
                    Notre équipe dévouée de professionnels qualifiés veille sur vos proches dans le confort de leur foyer, 
                    offrant un soutien personnalisé et une assistance attentive. Profitez de la commodité et de la sécurité de 
                    savoir que vos êtres chers sont entre de bonnes mains, 24 heures sur 24, 7 jours sur 7.
                </div>
                <img src={imageGarde} alt="garde à demeure"  className="img-aide"></img>
            </div>
            <div className="remplacements">
                <img src={imageRemplacements} alt="remplacements"  className="img-aide"></img>
                <div className="remplacement-texte">
                    <h2>Remplacements des aidants familiaux absents :</h2>
                    Quand les aidants familiaux sont absents, notre service de remplacement est là pour assurer une continuité de 
                    soins et de soutien sans faille. Nos professionnels compatissants et compétents sont prêts à prendre le relais, 
                    offrant une présence réconfortante et des soins de qualité supérieure à vos proches. Que ce soit pour quelques 
                    heures, une journée entière ou plus, nous nous engageons à garantir le bien-être et la sécurité de vos êtres chers 
                    en votre absence.
                </div>
            </div>
            <div className="agent-entretien">
                <div>
                    <h2>Agent d'entretien :</h2>
                    Offrez à votre foyer le soin et l'attention qu'il mérite avec notre service d'agent d'entretien à domicile. Nos 
                    professionnels qualifiés et fiables veillent à ce que votre espace reste propre, ordonné et accueillant, vous 
                    permettant ainsi de vous concentrer sur ce qui compte vraiment. Que ce soit pour un nettoyage régulier ou 
                    occasionnel, nous sommes là pour répondre à vos besoins. Profitez de la tranquillité d'esprit en sachant que 
                    votre maison est entre de bonnes mains avec notre service d'agent d'entretien à domicile.
                </div>
                <img src={imageAgentEntretien} alt="agent d'entretien"  className="img-aide"></img>
            </div>
            <div className="livraison-repas">
                <img src={imageLivraisonRepas} alt="livraison de repas à domicile"  className="img-aide"></img>
                <div className="livraison-texte">
                    <h2>Livraison de repas à domicile :</h2>
                    Offrez à vos proches la facilité et la satisfaction de repas livrés directement à leur porte avec notre service de 
                    livraison de repas à domicile. Que ce soit pour un repas quotidien ou une livraison planifiée hebdomadaire, nous 
                    veillons à ce que nos proches reçoivent des repas délicieux et équilibrés, tout en leur offrant le confort de 
                    rester chez eux. 
                </div>
            </div>
        </div>
    );
}

export default AideADomicile;