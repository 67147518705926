import "../styles/CreerCompte.css"
import chemin from "./Chemins"
import axios from "axios";

function verifierInfo(mail, mdp, verif_mdp)
{
    const regex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!_%&*])/);

    if (!/\S+@\S+\.\S+/.test(mail))
        return ("Veuillez renseignée une adresse mail correcte")
    else if (mdp.length < 7)
        return ("Le mot de passe doit contenir au minimum 8 caractères");
    else if (!regex.test(mdp))
        return ("Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial")
    else if (mdp !== verif_mdp)
        return ("Les mots de passe ne correspondent pas, veuillez réessayer en faisant attention à ne pas faire de faute de frappe");
    else
        return ("OK");
}

function CreerCompte({choixPage})
{
    let nouvel_utilisateur = {mail: "", mdp: ""};
    let verif_mdp = "";

    return (
        <div className="creation-compte">
            <h1 className="titre-creation-compte">Creation de compte :</h1>
            <div className="form-creation-compte">
                Adresse mail :<br></br>
                <input type="text" className="adresse-mail" onChange={(event) => {nouvel_utilisateur.mail = event.target.value;}}></input>
                <br></br>
                Mot de passe :<br></br>
                <input type="password" className="mdp" onChange={(event) => {nouvel_utilisateur.mdp = event.target.value;}}></input>
                <br></br>
                Confirmez le mot de passe :<br></br>
                <input type="password" className="mdp" onChange={(event) => {verif_mdp = event.target.value;}}></input>
                <br></br>
            </div>
            <button type="submit" className="bouton-envoyer" onClick={() => {
                const message_erreur = verifierInfo(nouvel_utilisateur.mail, nouvel_utilisateur.mdp, verif_mdp);
                if (message_erreur === "OK")
                    axios.post(chemin + "/utilisateurs/creer_utilisateur", nouvel_utilisateur).then((res) => {
                        alert(res.data.message);
                        if (res.data.verif)
                            choixPage(8);
                    });
                else
                    alert(message_erreur);
            }}>Envoyer</button>
        </div>
    );
}

export default CreerCompte;