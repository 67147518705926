import "../styles/AfficherDetailsDevis.css"
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import logo from "../images/logo_sos.png"
import chemin from './Chemins'

function AfficherDetailsDevis({mesDevis, details, afficherDetails})
{
    const urlImage = chemin + "/images/" + mesDevis.devis[details].images;
    let image = false;
    if (mesDevis.devis[details].images !== "")
        image = true;
    let regulier = false;
    if (mesDevis.devis[details].regularite === "Régulier")
        regulier = true;

    const styles = StyleSheet.create({
        page: { backgroundColor: 'white' },
        section: { color: 'black', textAlign: 'left', margin: 50 },
        text: {marginBottom: 10, fontSize: 12},
        image: {marginBottom: 10, width: 400},
        logo: {width: 50},
        titre: {textAlign: 'center', fontSize: 15}
        });
    const DevisPDF = () => 
    <Document>
        <Page size={"A4"} style={styles.page}>
            <View>
                <Image style={styles.logo} src={logo}></Image>
                <Text style={styles.titre}>Demande de devis n°{mesDevis.devis[details].id} :</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.text}>{mesDevis.devis[details].nom_prenom}</Text>
                <Text style={styles.text}>{mesDevis.devis[details].mail}</Text>
                <Text style={styles.text}>{mesDevis.devis[details].adresse}</Text>
                <Text style={styles.text}></Text>
                <Text style={styles.text}>Nature du travail demandé : {mesDevis.devis[details].nature_travail}</Text>
                <Text style={styles.text}>
                    {mesDevis.devis[details].regularite}
                    {regulier && (<Text style={styles.text}>{", " + mesDevis.devis[details].hps} heures par semaine</Text>)}
                    {regulier && (<Text style={styles.text}>{", " + mesDevis.devis[details].pps} passages par semaine</Text>)}
                </Text>
                <Text style={styles.text}></Text>
                <Text style={styles.text}>Description :</Text>
                <Text style={styles.text}>{'"' + mesDevis.devis[details].description + '"'}</Text>
                <Text style={styles.text}></Text>
                <Text style={styles.text}>Présence de nuisibles : {mesDevis.devis[details].nuisibles}</Text>
                <Text style={styles.text}>APA : {mesDevis.devis[details].apa}</Text>
                <Text style={styles.text}>PCH : {mesDevis.devis[details].pch}</Text>
                <Text style={styles.text}>Tutelle : {mesDevis.devis[details].tutelle}</Text>
            </View>
        </Page>
    </Document>

    return (
        <div className="details-devis">
            <button onClick={() => {afficherDetails(-1)}}>Retour à la liste des devis</button><br></br>
            <div className="desc-devis">
                <div>
                    Identité du demandeur : {mesDevis.devis[details].nom_prenom}
                </div>
                <div>
                    Adresse mail de contact : {mesDevis.devis[details].mail}
                </div>
                <div>
                    Adresse du demandeur : {mesDevis.devis[details].adresse}
                </div>
                <br></br>
                <div>
                    Nature du travail demandé : {mesDevis.devis[details].nature_travail}
                </div>
                <br></br>
                <div>
                    Régularité du travail : {mesDevis.devis[details].regularite}
                </div>
                {regulier && (
                    <div>
                        Nombres d'heures par semaine : {mesDevis.devis[details].hps}
                        <br></br>
                        Nombres de passages par semaine : {mesDevis.devis[details].pps}
                    </div>
                )}
                <br></br>
                <div>
                    Description :<br></br><br></br>{'"' + mesDevis.devis[details].description + '"'}
                </div>
                <br></br>
                {image && (<div>
                    <img src={urlImage} alt="Photo associée à la demande" className="image"></img>
                </div>)}
                <br></br>
                <div>
                    Présence de nuisibles : {mesDevis.devis[details].nuisibles}
                </div>
                <br></br>
                <div>
                    APA : {mesDevis.devis[details].apa} | PCH : {mesDevis.devis[details].pch} | Tutelle : {mesDevis.devis[details].tutelle}
                </div>
            </div>
            <br></br>
            <PDFDownloadLink document={<DevisPDF />} fileName="devis.pdf">
                {({loading}) => loading ? "Chargement" : "Télécharger la demande au format PDF" }
            </PDFDownloadLink>
        </div>
    );
}

export default AfficherDetailsDevis;