import "../styles/Chantier.css"

function Chantier()
{
    return (
        <div className="chantier corps">
            <div>
                
            </div>
        </div>
    );
}

export default Chantier;