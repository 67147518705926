import "../styles/PolitiqueConfidentialite.css"

function PolitiqueConfidentialite()
{
    return (
        <div className="politique-confidentialite">
            <h1>Politique de Confidentialité</h1>
            <p>SOS Diogene accorde une grande importance à la protection de vos données personnelles. Cette politique de confidentialité décrit les informations que nous collectons, comment nous les utilisons et les protégeons.</p>
            <h2>Collecte des informations :</h2>
            <p>Nous pouvons collecter des informations personnelles vous concernant lorsque vous utilisez nos services dans votre espace personnel. Ces informations peuvent inclure votre nom, votre adresse e-mail, votre numéro de téléphone, etc.</p>
            <h2>Utilisation des informations :</h2>
            <p>Les informations que nous collectons peuvent être utilisées pour :</p>
            <ul>
                <li>Vous fournir nos services et répondre à vos demandes ;</li>
                <li>Vous envoyer des informations concernant nos produits ou services ;</li>
            </ul>
            <h2>Protection des informations :</h2>
            <p>Nous mettons en place des mesures de sécurité pour protéger vos informations personnelles contre tout accès non autorisé, toute modification, toute divulgation ou toute destruction.</p>
            <h2>Partage des informations :</h2>
            <p>Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles à des tiers sans votre consentement, sauf dans les cas suivants :</p>
            <ul>
                <li>Si cela est nécessaire pour fournir un service que vous avez demandé ;</li>
                <li>Si cela est exigé par la loi ou pour protéger nos droits, notre propriété ou notre sécurité, ou ceux des autres.</li>
            </ul>
            <h2>Accès et contrôle de vos informations :</h2>
            <p>Vous pouvez accéder à vos informations personnelles, les mettre à jour ou les supprimer dans votre espace personnel. Vous pouvez également choisir de ne pas recevoir de communications de notre part en suivant les instructions de désinscription incluses dans ces communications.</p>
            <h2>Modification de la politique de confidentialité :</h2>
            <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec la date de mise à jour.</p>
            <p>Date de dernière mise à jour : 24/04/2024</p>
        </div>
    );
}

export default PolitiqueConfidentialite;