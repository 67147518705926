import "../styles/Intervention.css"
import carte from "../images/CarteYvelines.png"

function Intervention()
{
    return (
        <div className="intervention corps">
            <div className="intervention-texte">
                <h2>Zone d'intervention :</h2>
                <div>
                    Nous intervenons dans tout le département des Yvelines, le territoire de référence est Boucle de Seine. Nous intervenons
                    actuellement sur <b>Saint-Germain-En-Laye</b>, <b>Versailles</b>, <b>Mantes-la-Jolie</b>, <b>Poissy</b>.
                </div>
            </div>
            <img src={carte} alt="carte des yvelines" className="carte"></img>
        </div>
    );
}

export default Intervention;