import "../styles/MdpOublie.css"
import axios from "axios";
import chemin from "./Chemins";

function verifierMail(mail, verif_mail)
{
    if (!/\S+@\S+\.\S+/.test(mail))
        return ("Veuillez renseignée une adresse mail correcte")
    else if (mail !== verif_mail)
        return ("Les adresses ne correspondent pas, veuillez réessayer en faisant attention aux fautes de frappe")
    else
        return ("OK");
}

function MdpOublie({choixPage})
{
    let info = {
        mail: "",
    }
    let verif_mail = "";

    return (
        <div className="page-mdp-oublie">
            <h1>Mot de passe oublié :</h1>
            <div className="mail-mdp-oublie">
                <p>
                    Adresse mail du compte à récupérer :{" "}
                    <input type="text" onChange={(e) => {info.mail = e.target.value}}></input>
                </p>
                <p>
                    Confirmez l'adresse mail :{" "}
                    <input type="text" onChange={(e) => {verif_mail = e.target.value}}></input>
                </p>
            </div>
            <button className="bouton-reset" onClick={() => {
                const message_erreur = verifierMail(info.mail, verif_mail);
                if (message_erreur !== "OK")
                    alert(message_erreur);
                else
                    axios.post(chemin + "/utilisateurs/mdp_oublie", info).then((res) => {
                        alert(res.data.message);
                        if (res.data.verif)
                            choixPage(8);
                    });
            }}>Envoyer</button>
        </div>
    );
}

export default MdpOublie;