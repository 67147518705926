import "../styles/MultiService.css"

function MultiService()
{
    return (
        <div className="multiservice corps">
            <h1 className="titre">Nos autres services :</h1>
            <div className="multiservice-desc">
                <h2>Ces services s'adressent à la fois aux particuliers et aux professionnels :</h2>
                Découvrez nos offres multi-services complètes pour répondre à tous vos besoins en matière d'entretien et de rénovation 
                de locaux. Que vous ayez besoin d'un grand nettoyage en profondeur, d'un nettoyage classique régulier ou du déblaiement 
                de divers espaces, notre équipe expérimentée est là pour vous. Nous assurons le lessivage minutieux des murs, plafonds 
                et sols pour un environnement impeccable. De plus, nous proposons la préparation et la remise en état de locaux, ainsi 
                que la gestion efficace des déchets. Besoin de réparations ou de bricolages ? Nous sommes également à votre service. Et 
                pour un confort ultime, optez pour notre service de repassage avec possibilité de retrait et livraison au domicile.
                <br></br>
                <br></br>
                <h2>Nos services les plus demandés :</h2>
                <ul className="multiservice-list">
                    <li>Grand nettoyage</li>
                    <li>Nettoyage classique</li>
                    <li>Déblaiement de tous types de locaux</li>
                    <li>Lessivage des murs, plafonds et sols</li>
                    <li>Préparation des locaux et/ou Remise en état</li>
                    <li>Gestion des déchets</li>
                    <li>Bricolages et réparations</li>
                    <li>Repassage avec ou sans "retrait et livraison au domicile"</li>
                </ul>
            </div>
        </div>
    );
}

export default MultiService;