import "../styles/ModifierMdp.css"
import axios from "axios";
import chemin from "./Chemins";

function verifierMdp(mdp)
{
    const regex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!_%&*])/);

    if (mdp.mdp_actuel === "")
        return ("Veuillez remplir le champs \"Mot de passe actuel\"");
    else if (mdp.nouveau_mdp === "")
        return ("Veuillez remplir le champs \"Nouveau mot de passe\"");
    else if (mdp.nouveau_mdp_verif === "")
        return ("Veuillez remplir le champs \"Confirmez le nouveau mot de passe\"");
    else if (mdp.mdp_actuel === mdp.nouveau_mdp)
        return ("Le nouveau mot de passe doit être différent de l'ancien");
    else if (mdp.nouveau_mdp !== mdp.nouveau_mdp_verif)
        return ("Les nouveaux mots de passe ne correspondent pas");
    else if (mdp.nouveau_mdp.length < 7)
        return ("Le nouveau mot de passe doit contenir au moins 8 caractères.")
    else if (!regex.test(mdp.nouveau_mdp))
        return ("Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial")
    return ("OK");
}

function ModifierMdp({choixPage})
{
    let mdp = {
        mdp_actuel: "",
        nouveau_mdp: "",
        nouveau_mdp_verif: "",
    }

    return (
        <div className="modifier-mdp">
            <h2>Modifier votre mot de passe :</h2>
            <div className="form-nouveau-mdp">
                Mot de passe actuel :<br></br>
                <input name="mdp1" type="password" onChange={(e) => {mdp.mdp_actuel = e.target.value}}></input>
                <br></br><br></br>
                Nouveau mot de passe :<br></br>
                <input name="mdp2" type="password" onChange={(e) => {mdp.nouveau_mdp = e.target.value}}></input>
                <br></br>
                Confirmez le nouveau mot de passe :<br></br>
                <input name="mdp3" type="password" onChange={(e) => {mdp.nouveau_mdp_verif = e.target.value}}></input>
                <br></br>
            </div>
            <button className="bouton-reset" onClick={() => {
                const verif1 = verifierMdp(mdp);
                if (verif1 !== "OK")
                    alert(verif1);
                else
                {
                    axios.post(chemin + "/utilisateurs/modifier_mdp", mdp).then((res) => {
                        const message = res.data.message;
                        const verif = res.data.verif;

                        alert(message);
                        if (verif === true)
                            choixPage(8);
                    });
                }
            }}>Envoyer</button>
        </div>
    );
}

export default ModifierMdp;