import "../styles/Fonctionnement.css"
import fonctionnement from "../images/fct.jpg"
import publicImage from "../images/public2.jpg"
import organigramme from "../images/organigramme.png"

function Fonctionnement()
{
    return (
        <div className="fonctionnement corps">
            <h1 className="titre">Notre fonctionnement :</h1>
            <div className="engagement">
                <div className="texte">
                    <h2>Notre engagement :</h2>
                    Le projet SOS DIOGENE Services est de permettre à toute personne fragilisée par l'âge,
                    la maladie, le handicap, les difficultés sociales ou familiales, de rester à son domicile.
                    <br></br><br></br>
                    Nous souhaitons vous apporter une autonomie maximale, une existence digne, un
                    accompagnement dans le respect de votre intimité, tout en soulageant les
                    personnes de votre entourage qui contribuent à la réalisation de ce choix de vie.
                    <br></br><br></br>
                    La réalisation de ces objectifs repose sur une évaluation régulière de vos besoins et
                    de vos attentes et sur la mise à disposition de prestations adaptées.
                </div>
                <img src={fonctionnement} alt="fonctionnement" className="img"></img>
            </div>

            <div className="public">
                <img src={publicImage} alt="public" className="img"></img>
                <div className="texte">
                    <h2>Le public concerné :</h2>
                    Quel que soit votre âge, votre situation sociale et financière, la composition de votre 
                    famille… SOS DIOGENE est en mesure de vous proposer un service adapté.
                    <br></br><br></br>
                    Nous intervenons notamment auprès :
                    <ul>
                        <li>de personnes agées</li>
                        <li>de personnes handicapées</li>
                        <li>de personnes malades</li>
                        <li>de personnes de retour d'hospitalisation</li>
                    </ul>
                </div>
            </div>

            <div className="organisation">
                <div className="organigramme">
                    <h2 className="titre2">L'organigramme :</h2>
                    <img src={organigramme} alt="organigramme" className="img"></img>
                </div>
                <div className="responsables">
                    <div className="dirigeant">
                        <h2>Le dirigeant - responsable de secteur :</h2>
                        Dès votre premier contact, le responsable de secteur sera votre référent.
                        <br></br>
                        Il a pour mission :
                        <br></br>
                        <ul>
                            <li>de se déplacer à votre domicile afin d'évaluer votre demande et définir le cadre de l'intervention</li>
                            <li>de vous aider à définir vos besoins</li>
                            <li>de vous proposer les financements possibles</li>
                            <li>de vous aider à constituer le dossier de prise en charge</li>
                            <li>de désigner l'intervenant adapté à votre situation</li>
                            <li>de répondre à vos questions, de recevoir vos doléances et de proposer des solutions</li>
                            <li>de modifier et d'adapter la nature de l'intervention à l'évolution des besoins.</li>
                            <li>d'animer des réunions techniques</li>
                        </ul>
                    </div>
                    <div className="responsable-chantier">
                        <h2>Le responsable de chantier :</h2>
                        Il a pour mission :
                        <br></br>
                        <ul>
                            <li>de diriger une équipe plus ou moins nombreuse selon l'importance du chantier.</li>
                            <li>d'organiser et distribuer les tâches selon les corps de métiers et le planning de réalisation du chantier.</li>
                            <li>d'animer les ouvriers de son équipe. Il a également la charge des questions administratives.</li>
                            <li>de garantir la sécurité des hommes et des machines, le respect de la réglementation en vigueur et les délais d'exécution.</li>
                            <li>d'assurer la communication avec sa hiérarchie pour la tenir au courant de la marche des travaux.</li>
                        </ul>
                    </div>
                </div>

                <div className="agents">
                    <div className="aux">
                        <h2>L'auxiliaire de vie :</h2>
                        L'Auxiliaire de vie a pour mission principale 
                        d'accompagner, d'aider et de veiller à l'autonomie et au maintien à domicile 
                        des personnes âgées, des personnes en situation de handicap et des personnes 
                        dépendantes.
                        <br></br>
                        Il/Elle accomplit des tâches et des actes de la vie quotidienne, tels 
                        que des tâches domestiques (préparation des repas, ménages, courses, 
                        repassage etc…), des soins d'hygiène (toilette, changes etc…) et de l'aide à la 
                        mobilité et au déplacement (transferts, transports, promenades etc…).
                    </div>
                    <div className="htm">
                        <h2>L'homme toutes mains :</h2>
                        Il réalise des petits travaux de bricolage à domicile 
                        consistant à effectuer des tâches occasionnelles simples et de courte durée de 
                        manutention, de nettoyage, d'entretien, de réparation, de maintenance, de 
                        rénovation, d'installation ou de décoration sur le lieu d'habitation.
                        <br></br>
                        L'organisation du travail est donc totalement à la discrétion de l'homme toutes 
                        mains. Les travaux sont réalisés à l'intérieur comme à l'extérieur du domicile et 
                        s'effectuent le plus souvent debout avec de nombreux déplacements.
                    </div>
                    <div className="fdm">
                        <h2>La femme de ménage :</h2>
                        Entretient le linge de maison et les vêtements de la
                        personne, réalise des courses alimentaires ou autres, prépare des aliments (épluchage, lavage, ...) ou 
                        réalise des repas.
                        <br></br>
                        Garde des enfants scolarisés et les accompagne dans leurs activités quotidiennes
                        (trajet maison-école, repas/goûter, sorties, ...)
                        <br></br>
                        Nourrit, promène et entretient l'environnement des animaux domestiques.
                        <br></br>
                        Réalise des travaux de maintenance simple ou de manutention au domicile de la
                        personne.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fonctionnement;